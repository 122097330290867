import 'regenerator-runtime/runtime';
//Utilities
export { default as ApiUtilities } from './Utilities/ApiUtilities';
export { default as Middleware } from './Utilities/Middleware';
export { default as DownloadUtilities } from './Utilities/DownloadUtilities';
//External Libraries
window.One_Ring_Version = '1.7.1';
window.Versions = window.Versions || [];
if (
    window.Versions.findIndex((v) => {
        return v.package === 'one-ring';
    }) < 0
) {
    window.Versions.push({ package: 'one-ring', name: 'One Ring', description: 'API Management', version: window.One_Ring_Version });
}
