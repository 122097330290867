import DuDateUtilities from './DuDateUtilities';

var writeExcelFile = function (filename, columns, data, name = "Driscoll's") {
    //TODO: We need to add validation

    //TODO: We need to add styling ability

    var dataToSave = [
        columns.map((c) => {
            if (typeof c.name === 'string') {
                return c.name;
            }
            return (c.name.props || [])['tipText'];
        })
    ];

    data.forEach((d) => {
        var row = columns.map((c) => d[c.key]);
        dataToSave.push(row);
    });

    var worksheet = XLSX.utils.aoa_to_sheet(dataToSave);
    var new_workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(new_workbook, worksheet, name);
    XLSX.writeFile(new_workbook, filename);
};

var handleExcelDrop = function (e, returnFunction, statusFunction) {
    e.preventDefault();
    e.stopPropagation();

    var files = e.dataTransfer.files,
        f = files[0];

    readExcelFile(f, returnFunction, statusFunction);
};

var createExcelDrop = function (element, returnFunction, statusFunction) {
    element.addEventListener(
        'drop',
        (e) => {
            handleExcelDrop(e, returnFunction, statusFunction);
        },
        false
    );
};

var handleFiles = function (fileList, returnFunction, statusFunction) {
    if (!fileList || fileList.length <= 0) {
        return;
    }

    var f = fileList[0];
    readExcelFile(f, returnFunction, statusFunction);
};

var readExcelFile = async (file, returnFunction, statusFunction) => {
    var startTime = new Date();
    var reader = new FileReader();

    var extension = file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.') + 1) : '';

    if (extension !== 'xlsx' && extension !== 'xls' && extension !== 'xlsb') {
        returnFunction(null, { fileName: file.name, msElapsed: 0, timeElapsed: 'Failed', message: 'File Extension is not valid, please upload an excel document.' });
        return;
    }

    setStatus(statusFunction, -1, 'Starting to Open Excel File');

    reader.onload = function (e) {
        setStatus(statusFunction, -1, 'Excel File Open, ready to parse');
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: 'array' });
        setStatus(statusFunction, -1, 'Excel File Parsed');
        var endTime = new Date();

        returnFunction(workbook, { fileName: file.name, msElapsed: endTime - startTime, timeElapsed: DuDateUtilities.TimeSpanToString(startTime, endTime) });
    };

    reader.readAsArrayBuffer(file);
};

const readRows = async (workbook, sheetName, statusFunction) => {
    return new Promise((resolve) => {
        var rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: "" });
        resolve(rows);
    });
};

const setStatus = async (statusFunction, percentDone, message) => {
    const isIndeterminate = percentDone < 0;
    statusFunction({ isIndeterminate, percentDone, message });
};

var getRow = function (data, sheetName, row, columnStart, columnEnd) {
    var sheet = data[sheetName];

    if (!sheet) {
        return [];
    }

    var row = [];
    var sheetKeys = Object.keys(sheet);

    for (var i = columnStart; i <= columnEnd; i++) {
        var cellKey = `${i}${row}`;
        if (!sheetKeys.includes(cellKey)) {
            continue;
        }

        row.push(sheet[cellKey]);
    }
};

/*
   TODO: Add following functions to support excel import more easily
   GetRows(data, sheetName, startRow, endRow, columnStart, columnEnd);
   GetRow(data, sheetName, row, columnStart, columnEnd);
   GetCell(data, sheetName, row, column);
   GetColumn(data, sheetName, columnStart, columnEnd, row);

   GetRowsWithTemplate(data, sheetName, template, startRow, endRow, columnStart, columnEnd);
   GetRowWithTemplate(data, sheetName, template, row, columnStart, columnEnd);
   GetColumnWithTemplate(data, sheetName, template, columnStart, columnEnd, row);
*/

const DuExcelUtilities = {
    Write: writeExcelFile,
    Read: readExcelFile,
    HandleFiles: handleFiles,
    CreateExcelDrop: createExcelDrop,
    GetRow: getRow,
    ReadRows: readRows
};

export default DuExcelUtilities;
