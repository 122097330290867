const convertHslToHex = (hsl) => {
    var hslAr = hsl.split(',');

    if (hslAr.length < 3) {
        return '#000';
    }

    var h = Number(hslAr[0].substr(hslAr[0].indexOf('(') + 1));
    var s = Number(hslAr[1].replace('%', ''));
    var l = Number(hslAr[2].replace('%', '').replace(')', ''));

    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
        m = l - c / 2,
        r = 0,
        g = 0,
        b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (120 <= h && h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        g = 0;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        g = 0;
        b = x;
    }

    // Having obtained RGB, convert channels to hex
    r = Math.round((r + m) * 255).toString(16);
    g = Math.round((g + m) * 255).toString(16);
    b = Math.round((b + m) * 255).toString(16);

    // Prepend 0s, if necessary
    if (r.length == 1) {
        r = '0' + r;
    }

    if (g.length == 1) {
        g = '0' + g;
    }

    if (b.length == 1) {
        b = '0' + b;
    }

    return '#' + r + g + b;
};

const convertHexToHsl = (H) => {
    // Convert hex to RGB first
    let r = 0,
        g = 0,
        b = 0;
    if (H.length == 4) {
        r = '0x' + H[1] + H[1];
        g = '0x' + H[2] + H[2];
        b = '0x' + H[3] + H[3];
    } else if (H.length == 7) {
        r = '0x' + H[1] + H[2];
        g = '0x' + H[3] + H[4];
        b = '0x' + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;

    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta == 0) {
        h = 0;
    } else if (cmax == r) {
        h = ((g - b) / delta) % 6;
    } else if (cmax == g) {
        h = (b - r) / delta + 2;
    } else {
        h = (r - g) / delta + 4;
    }

    h = Math.round(h * 60);

    if (h < 0) {
        h += 360;
    }

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return 'hsl(' + h + ',' + s + '%,' + l + '%)';
};

const updateHslBrightness = (hsl, addition) => {
    var currentHslPartA = hsl.substr(0, hsl.lastIndexOf(',') + 1);
    var currentHslPartB = hsl.substr(hsl.lastIndexOf('%'));
    var currentHslBrightness = Number(hsl.substr(hsl.lastIndexOf(',') + 1, hsl.lastIndexOf('%') - hsl.lastIndexOf(',') - 1));

    return currentHslPartA + Math.max(0, Math.min(100, currentHslBrightness + addition)) + currentHslPartB;
};

const setHslBrightness = (hsl, brightness) => {
    var currentHslPartA = hsl.substr(0, hsl.lastIndexOf(',') + 1);
    var currentHslPartB = hsl.substr(hsl.lastIndexOf('%'));

    return currentHslPartA + Math.max(0, Math.min(100, brightness)) + currentHslPartB;
};

const updateHslSaturation = (hsl, addition) => {
    var currentHslPartA = hsl.substr(0, hsl.indexOf(',') + 1);
    var currentHslPartB = hsl.substr(hsl.indexOf('%'));
    var currentHslSaturation = Number(hsl.substr(hsl.indexOf(',') + 1, hsl.indexOf('%') - hsl.indexOf(',') - 1));

    return currentHslPartA + Math.max(0, Math.min(100, currentHslSaturation + addition)) + currentHslPartB;
};

const setHslSaturation = (hsl, saturation) => {
    var currentHslPartA = hsl.substr(0, hsl.indexOf(',') + 1);
    var currentHslPartB = hsl.substr(hsl.indexOf('%'));

    return currentHslPartA + Math.max(0, Math.min(100, saturation)) + currentHslPartB;
};

const updateHslOpacity = (hsl, opacity) => {
    var hslParts = hsl.split(',');
    if (hslParts.length < 3) {
        return hsl;
    }

    if (opacity > 1) {
        opacity /= 100;
    }

    return (hslParts.length > 3 ? hslParts[0] : hslParts[0].replace('hsl', 'hsla')) + ',' + hslParts[1] + ',' + hslParts[2].replace(')', '') + ',' + opacity.toFixed(2) + ')';
};

const defaultColors = {
    primary: {
        green: '#4a773c',
        red: '#da291c',
        pink: '#c63663',
        purple: '#6f5091',
        blue: '#0076a5',
        yellow: '#fdda24'
    },
    secondary: {
        error: {
            light: '#c54',
            dark: '#400'
        },
        warning: {
            light: '#fe9',
            dark: '#971'
        }
    },
    text: {
        black: {
            main: '#222',
            light: '#444',
            dark: '#000'
        },
        white: {
            main: '#eee',
            light: '#fff',
            dark: '#ddd'
        }
    }
};

const defaultFontFamily = [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
].join(',');

const roboto = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `src: local('Roboto'), local('Roboto-Regular'), url(/Roboto_Ext.woff2) format('woff2');`,
    unicodeRange: 'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;'
};

const createSimpleThemeConfig = (lightPrimary, darkPrimary) => {
    var isDarkTheme = window && window.matchMedia ? window.matchMedia('(prefers-color-scheme: dark)').matches : false;
    var isHsl = lightPrimary.indexOf('hsl') >= 0;

    if (!darkPrimary) {
        darkPrimary = lightPrimary;
    }

    //Light Color HSL Values
    var lightPrimaryHsl = isHsl ? lightPrimary : convertHexToHsl(lightPrimary);
    var lightPrimaryHslLight = updateHslBrightness(lightPrimaryHsl, 10);
    var lightPrimaryHslDark = updateHslBrightness(lightPrimaryHsl, -10);

    //Light Color Hex Values
    var lightPrimaryHex = isHsl ? convertHslToHex(lightPrimaryHsl) : lightPrimary;
    var lightPrimaryHexLight = convertHslToHex(lightPrimaryHslLight);
    var lightPrimaryHexDark = convertHslToHex(lightPrimaryHslDark);

    //Dark Colors HSL Values
    var darkPrimaryHsl = isHsl ? darkPrimary : convertHexToHsl(darkPrimary);
    var darkPrimaryHslLight = updateHslBrightness(darkPrimaryHsl, 10);
    var darkPrimaryHslDark = updateHslBrightness(darkPrimaryHsl, -10);

    //Dark Colors Hex Values
    var darkPrimaryHex = isHsl ? convertHslToHex(darkPrimaryHsl) : darkPrimary;
    var darkPrimaryHexLight = convertHslToHex(darkPrimaryHslLight);
    var darkPrimaryHexDark = convertHslToHex(darkPrimaryHslDark);

    currentTheme = {
        palette: {
            type: isDarkTheme ? 'dark' : 'light',
            primary: {
                light: isDarkTheme ? darkPrimaryHexLight : lightPrimaryHexLight,
                main: isDarkTheme ? darkPrimaryHex : lightPrimaryHex,
                dark: isDarkTheme ? darkPrimaryHexDark : lightPrimaryHexDark,
                contrastText: defaultColors.text.white.main
            },
            secondary: {
                light: defaultColors.secondary.error.light,
                main: defaultColors.secondary.error.light,
                dark: defaultColors.secondary.error.dark,
                contrastText: defaultColors.text.white.main
            },
            error: {
                light: defaultColors.secondary.error.light,
                main: defaultColors.secondary.error.light,
                dark: defaultColors.secondary.error.dark,
                contrastText: defaultColors.text.white.main
            }
        },
        typography: {
            fontFamily: defaultFontFamily
        },
        light: {
            body: defaultColors.text.white.main,
            primary: defaultColors.text.white.light,
            secondary: defaultColors.text.white.main,
            transparent: '#333C',
            error: defaultColors.secondary.error.light,
            warning: defaultColors.secondary.warning.light,
            buttonBorder: '#bbb',
            text: {
                primary: defaultColors.text.black.main,
                secondary: defaultColors.text.black.light,
                inverted: defaultColors.text.white.light,
                disabled: defaultColors.text.black.light,
                error: defaultColors.text.black.dark,
                errorTitle: defaultColors.secondary.error.light,
                variant: {
                    primary: defaultColors.text.white.light,
                    secondary: defaultColors.text.white.dark
                }
            },
            accent: {
                primary: lightPrimaryHex,
                secondary: lightPrimaryHexLight,
                brightness: (percent) => {
                    return updateHslBrightness(lightPrimaryHsl, percent);
                },
                opacity: (opacity) => {
                    return updateHslOpacity(lightPrimaryHsl, opacity);
                },
                text: {
                    primary: lightPrimaryHex
                }
            },
            variant: {
                primary: defaultColors.text.black.dark,
                secondary: defaultColors.text.black.main
            },
            border: {
                middle: '#8888'
            },
            grid: {
                color1: setHslBrightness(lightPrimaryHsl, 97),
                color2: setHslBrightness(setHslSaturation(lightPrimaryHsl, 30), 80),
                color3: setHslBrightness(updateHslSaturation(lightPrimaryHsl, -20), 75),
                hover: defaultColors.text.white.dark,
                even: defaultColors.text.white.light,
                odd: defaultColors.text.white.main
            },
            header: {
                color1: 'hsla(0, 0%, 95%)',
                color2: 'hsla(0, 0%, 90%)',
                color3: 'hsla(0, 0%, 75%)',
                shadow: 'hsl(0, 0%, 60%)'
            },
            shadow: [
                '9px 9px 16px ' + updateHslOpacity(updateHslSaturation(lightPrimaryHsl, -20), 0.3),
                '-9px -9px 16px ' + updateHslOpacity(updateHslSaturation(lightPrimaryHsl, -20), 0.1),
                '0 0 8px ' + updateHslOpacity(lightPrimaryHsl, 0.5),
                '0 0 8px ' + updateHslOpacity(lightPrimaryHsl, 0.25)
            ]
        },
        dark: {
            body: defaultColors.text.black.main,
            primary: defaultColors.text.black.dark,
            secondary: defaultColors.text.black.main,
            transparent: '#3338',
            error: defaultColors.secondary.error.dark,
            warning: defaultColors.secondary.warning.dark,
            buttonBorder: '#555555',
            text: {
                primary: defaultColors.text.white.main,
                secondary: defaultColors.text.white.light,
                inverted: defaultColors.text.black.main,
                disabled: '#888',
                error: defaultColors.text.white.dark,
                errorTitle: defaultColors.secondary.error.light,
                variant: {
                    primary: defaultColors.text.black.dark,
                    secondary: defaultColors.text.black.main
                }
            },
            accent: {
                primary: lightPrimaryHex,
                secondary: lightPrimaryHexLight,
                brightness: (percent) => {
                    return updateHslBrightness(darkPrimaryHsl, percent);
                },
                opacity: (opacity) => {
                    return updateHslOpacity(darkPrimaryHsl, opacity);
                },
                text: {
                    primary: lightPrimaryHex
                }
            },
            variant: {
                primary: defaultColors.text.white.light,
                secondary: defaultColors.text.white.dark
            },
            border: {
                middle: '#8888'
            },
            grid: {
                color1: '#171817',
                color2: '#3d403d',
                color3: '#464646',
                hover: defaultColors.text.black.light,
                even: '#151515',
                odd: defaultColors.text.black.main
            },
            header: {
                color1: 'hsla(0, 0%, 20%)',
                color2: 'hsla(0, 0%, 15%)',
                color3: 'hsla(0, 0%, 16%)',
                shadow: 'hsl(0, 0%, 33%)'
            },
            shadow: [
                '9px 9px 16px ' + updateHslOpacity(updateHslSaturation(darkPrimaryHsl, -20), 0.3),
                '-9px -9px 16px ' + updateHslOpacity(updateHslSaturation(darkPrimaryHsl, -20), 0.1),
                '0 0 8px ' + updateHslOpacity(darkPrimaryHsl, 0.5),
                '0 0 8px ' + updateHslOpacity(darkPrimaryHsl, 0.25)
            ]
        },
        darkTheme: '@media (prefers-color-scheme: dark)',
        important: '!important',
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '@font-face': [roboto]
                }
            }
        }
    };

    return currentTheme;
};

let currentTheme = createSimpleThemeConfig(defaultColors.primary.green);

const buttonThemes = {
    Standard: 0,
    Polymorphism: 1,
    Hard: 2,
    BottomLine: 4
};

var currentBtnTheme = buttonThemes.Standard;
const currentButtonTheme = () => {
    return currentBtnTheme;
};

const registerTheme = (theme) => {
    currentBtnTheme = theme;
};

const validButtonTheme = (theme) => {
    if (theme & buttonThemes.Polymorphism) {
        return !(theme & buttonThemes.Hard);
    }

    return true;
};

const hasTheme = (themeA, themeB) => {
    return (themeA & themeB) > 0;
};

const DuThemeUtilities = {
    CurrentTheme: currentTheme,
    CreateSimpleThemeConfig: createSimpleThemeConfig,
    ConvertHslToHex: convertHslToHex,
    ConvertHexToHsl: convertHexToHsl,
    UpdateHslBrightness: updateHslBrightness,
    UpdateHslOpacity: updateHslOpacity,
    SetHslBrightness: setHslBrightness,
    SetHslSaturation: setHslSaturation,
    DefaultColors: defaultColors,
    ButtonThemes: buttonThemes,
    CurrentButtonTheme: currentButtonTheme,
    RegisterTheme: registerTheme,
    ValidButtonTheme: validButtonTheme,
    HasTheme: hasTheme
};

export default DuThemeUtilities;
