const LANGUAGES = [
    { name: 'English', code: 'en' },
    { name: 'Español', code: 'es' },
    { name: '汉语', code: 'zh-CN' },
    { name: 'Français', code: 'fr' }
];

// [English, Spanish, Chinese, French]
const COMMON_TRANSLATIONS = {
    buttons: {
        okay: ['Okay', 'Bueno'],
        yes: ['Yes', 'Si'],
        no: ['No', 'No'],
        cancel: ['Cancel', 'Cancelar'],
        continue: ['Continue', 'Seguir'],
        back: ['Back', 'Regresa'],
        oops: ['Oops, Just Kidding', 'Vaya, es broma'],
        submit: ['Submit', 'Enviar'],
        abort: ['Abort', 'Abortar'],
        agree: ['Agree', 'Acordar'],
        add: ['Add', 'Añadir'],
        delete: ['Delete', 'Eliminar'],
        download: ['Download', 'Descargar'],
        save: ['Save', 'Salvar'],
        accept: ['Accept', 'Aceptar']
    },
    fields: {
        requiredField: ['This field is required', 'Este campo es requerido']
    },
    roles: {
        admin: ['Admin', 'Admin'],
        user: ['User', 'Usuario'],
        readOnly: ['Read Only', 'Solo Lectura']
    }
};

const DuCommonTranslations = { COMMON_TRANSLATIONS, LANGUAGES };

export default DuCommonTranslations;
