import DuDateUtilities from './DuDateUtilities';

var createFileDrop = function(element, returnFunction, statusFunction, acceptedFiles) {
    element.addEventListener(
        'drop',
        (e) => {
            handleFileDrop(e, returnFunction, statusFunction, acceptedFiles);
        },
        false
    );
};

var handleFileDrop = function(e, returnFunction, statusFunction, acceptedFiles) {
    e.preventDefault();
    e.stopPropagation();

    var files = e.dataTransfer.files,
        f = files[0];
    readFile(f, returnFunction, statusFunction, acceptedFiles);
};

const setStatus = async (statusFunction, percentDone, message) => {
    const isIndeterminate = percentDone < 0;
    statusFunction({ isIndeterminate, percentDone, message });
};

var handleFiles = function(fileList, returnFunction, statusFunction, acceptedFiles) {
    if (!fileList || fileList.length <= 0) {
        return;
    }

    var f = fileList[0];
    readFile(f, returnFunction, statusFunction, acceptedFiles);
};

var readFile = async (file, returnFunction, statusFunction, acceptedFiles) => {
    var startTime = new Date();

    var extension = file.name.lastIndexOf('.') > 0 ? file.name.substring(file.name.lastIndexOf('.') + 1) : '';
    if (!acceptedFiles.includes('.' + extension)) {
        returnFunction(null, { fileName: file.name, msElapsed: 0, timeElapsed: 'Failed', message: 'File Extension is not valid, please upload a document.' });
        return;
    }

    setStatus(statusFunction, -1, 'Starting to Open File');
    var endTime = new Date();

    returnFunction(file, { fileName: file.name, msElapsed: endTime - startTime, timeElapsed: DuDateUtilities.TimeSpanToString(startTime, endTime) });
};

const readRows = async (workbook, sheetName, statusFunction) => {
    return new Promise((resolve) => {
        var rows = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: "" });
        resolve(rows);
    });
};

const DuUploadUtilities = {
    CreateFileDrop: createFileDrop,
    ReadRows: readRows,
    HandleFiles: handleFiles
};

export default DuUploadUtilities;
